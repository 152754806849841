@media only screen and (max-width: 600px) {
    th {
        white-space: normal !important; 
        word-wrap: break-word;  
    }

    td {
        white-space: normal !important; 
        word-wrap: break-word;  
    }
    
    table {
        table-layout: fixed;
    }
}

html {
    scroll-behavior: smooth;
}

#root {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
}

label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.users-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.noPadding {
    padding: 0px !important;
}

.noMargins {
    margin: 0px !important;
}

.enabled-checkbox {
    width: 100%;
}

.enabled-checkbox > label {
    width: 90%;
    margin: 0px !important;
    justify-content: left !important;
}

.enabled-checkbox > input {
    width: 7% !important;
}

.participant-card {
    margin-top: 0px !important;
}

.participant-search-bar {
    width: 75% !important;
    margin-right: 5%;
}

.participant-add-btn {
    width: 20% !important;
}

.card-text>div {
    display: inline;
}

.search-error {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.session-circle {
    color: white;
    border-radius: 3px;
    height: 1rem;
    width: 100%;
    white-space: nowrap;
    margin-top: 1rem;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis !important;
}

.red {
    background-color: red;
}

.blue {
    background-color: blue;
}

.green {
    background-color: green;
}

.purple {
    background-color: purple;
}

.inline {
    display: inline !important;
}

/* Override calendar styles */
.react-calendar {
    width: auto !important;
}

.react-calendar__tile--hasActive {
    background: #006edc !important;
    color: white !important;
}

.react-calendar__tile {
    min-height: 6rem !important;
}

.today-text {
    padding: 5px;
}

.create-sesion-btn {
    margin: 1rem;
}

.sessions-header {
    margin-top: 3rem;
}

.session-time {
    display: inline;
    color: white;
    border-radius: 15px;
    padding: 0.5rem;
    font-size: 1.5rem;
    margin-right: 1rem;
}

.deletesession-img {
    height: 100%;
    width: 100%;
}

.deletesession-btn {
    height: 3.5rem;
    width: 3.5rem;
    padding: 0px;
}

.minus-img {
    height: 100%;
    width: 100%;
}

.minus-btn {
    height: 2rem;
    width: 2rem;
    padding: 0px;
}

.session-owner {
    margin-top: 1rem;
}

.nav-link:hover {
    cursor: pointer;
}

.break-text {
    word-break: break-all;
}

/* link in profile page */
.profile-link {
    display: inline-block;
    margin-top: 1rem !important;
}